import enumsEn from '@/locales/enum-en.json'
import enumsSk from '@/locales/enum-sk.json'

export default {
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    getEnumList(names, ...lists) {
      let enums = enumsEn
      if (this.$i18n.locale === 'sk') {
        enums = enumsSk
      }
      if (!(names instanceof Array)) {
        names = [names]
      }
      return new Promise((resolve) => {
        this.$Enums.getCollection({params: {pagination: false, cache: true}})
          .then(response => {
            names.forEach((name, index) => {
              response.data['hydra:member'].forEach(element => {
                if (name === element.name) {
                  element.keys.forEach(key => {
                    lists[index].push({
                      label: enums[name][key],
                      id: key,
                    })
                  })
                }
              })
            })
            resolve(lists)
          })
      })
    },

    getUserList(args, ...lists) {
      const { params } = args
      params.pagination = false
      if (params.role) {
        if (!(params.role instanceof Array)) {
          params.role = [params.role]
        }
      }
      return new Promise((resolve) => {
        this.$Users.getCollection({params}, 'user_list')
          .then(response => {
            response.data['hydra:member'].forEach(element => {
              if (params.role) {
                params.role.forEach((role, index) => {
                  if (role === element.role) {
                    lists[index].push({
                      label: `${element.givenName} ${element.familyName}`,
                      id: element['@id'],
                      numberId: element.id,
                      user: element
                    })
                  }
                })
              } else {
                lists[0].push({
                  label: `${element.givenName} ${element.familyName}`,
                  id: element['@id'],
                  numberId: element.id,
                  user: element
                })
              }
            })
            resolve(lists)
          })
      })
    },

    getList(resource, list, serializationGroup, exclude = null) {
      return new Promise(resolve => {
        const params = {
          pagination: false
        }
        resource.getCollection({params}, serializationGroup)
          .then(response => {
            response.data['hydra:member'].forEach(element => {
              if (exclude !== element.id) {
                list.push({
                  label: `${element.name}`,
                  id: element['@id'],
                  numberId: element.id,
                })
              }
            })
            resolve(list)
          })
      })
    },

    getPageList(list, exclude = null) {
      return this.getList(this.$WebPages, list, 'id_name', exclude)
    },

    getArticleList(list, exclude = null) {
      return this.getList(this.$Articles, list, 'id_name', exclude)
    },

    getCategoriesList(list, exclude = null) {
      return this.getList(this.$Categories, list, 'id_name', exclude)
    },

    getBannerSlidesList(list) {
      return this.getList(this.$BannerSlides, list, 'id_name')
    },

    getParametersList() {
      const list = []
      return new Promise(resolve => {
        const params = {
          pagination: false
        }
        this.$Parameters.getCollection({params})
          .then(response => {
            response.data['hydra:member'].forEach(element => {
              const items = []
              element.items.forEach(i => {
                items.push({
                  id: i.value,
                  label: i.name,
                  parameter: element
                })
              })

              list.push({
                label: `${element.name}`,
                id: element['@id'],
                numberId: element.id,
                type: element.type,
                url: element.url,
                items
              })
            })
            resolve(list)
          })
      })
    },

    getConfigurationsList() {
      const list = []
      return this.getList(this.$Configurations, list, 'id_name')
    },

    getProductsList(list) {
      return this.getList(this.$Products, list, 'id_name')
    },

    getProductVariantsList(list) {
      return this.getList(this.$ProductVariants, list, 'id_name')
    },

    getVariablesList(list, exclude = null) {
      return this.getList(this.$Variables, list, 'id_name', exclude)
    },

    getReviewsList(list, exclude = null) {
      return this.getList(this.$Reviews, list, 'id_name', exclude)
    },

    getLocationList(list) {
      return new Promise(resolve => {
        const params = {
          pagination: false,
          cache: true,
        }
        this.$Locations.getCollection({params}, 'locality_list')
          .then(response => {
            response.data['hydra:member'].forEach(element => {
              list.push({
                label: `${element.locality} (${element.airport})`,
                id: element['@id'],
                numberId: element.id,
              })
            })
            resolve(list)
          })
      })
    },
  },
}
