<template>
  <div class="search-container d-flex align-items-center">
    <label>{{ label ? label : $t('forms.search') }}</label>
    <b-form-input v-model="inputValue" class="search ms-3" @input="onInputChange"></b-form-input>
    <b-button class="ms-3" v-if="showBtn" variant="primary" @click="$emit('btnSubmit', inputValue)">{{ $t('forms.search') }}</b-button>
  </div>
</template>

<script>
export default {
  name: 'Search',
  components: { },
  props: {
    showBtn: {type: Boolean, default: false },
    label: { type: String, default: '' },
    external: { type: String, default: '' }
  },
  data() {
    return {
      timeout: null,
      inputValue: '',
    }
  },
  watch: {
    external(value) {
      if(value != this.inputValue){
        this.inputValue = value
      }
    }
  },
  created () {
    if(this.external) {
      this.inputValue = this.external
    }
  }, 
  methods: {
    onInputChange(value) {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        if(value.length > 2 || !value.length) {
          this.inputValue = value
          this.$emit('inputChange', this.inputValue)
        }
      }, 500)
    },
  },
}
</script>
