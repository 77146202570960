<template>
  <div class="content-box">
    <div class="search-bar">
      <h4>{{ $t("record.title") }}</h4>
      <b-alert :show="showWarning" class="block">{{ $t("record.sendWarningMessage") }}</b-alert>
      <search
        @inputChange="searchInput"
        @btnSubmit="searchSubmit"
        :showBtn="true"
        :external="search"
        :label="$t('voucher.code')"
      />
    </div>

    <div v-if="!notActivated" class="record-form-container">
      <b-form @submit.prevent="onSubmit" v-if="voucher">
        <h4>{{ $t("record.check") }}</h4>
        <b-form-group
          :class="{ row, error: v$.recipient.givenName.$errors.length }"
          :label="`${$t('user.name')} *`"
          class="form-group"
        >
          <b-form-input v-model="recipient.givenName" />
          <div
            class="input-errors"
            v-for="error of v$.recipient.givenName.$errors"
            :key="error.$uid"
          >
            <small class="text-danger">{{ error.$message }}</small>
          </div>
        </b-form-group>

        <b-form-group
          :class="{ row, error: v$.recipient.familyName.$errors.length }"
          :label="`${$t('user.familyName')} *`"
          class="form-group"
        >
          <b-form-input v-model="recipient.familyName" />
          <div
            class="input-errors"
            v-for="error of v$.recipient.familyName.$errors"
            :key="error.$uid"
          >
            <small class="text-danger">{{ error.$message }}</small>
          </div>
        </b-form-group>

        <b-form-group
          :class="{ row, error: v$.recipient.email.$errors.length }"
          :label="`${$t('user.email')} *`"
          class="form-group"
        >
          <b-form-input v-model="recipient.email" @keydown.space.prevent />
          <div
            class="input-errors"
            v-for="error of v$.recipient.email.$errors"
            :key="error.$uid"
          >
            <small class="text-danger">{{ error.$message }}</small>
          </div>
        </b-form-group>

        <b-form-group
          :class="{ row, error: v$.recipient.telephone.$errors.length }"
          :label="`${$t('user.telephone')} *`"
          class="form-group"
        >
          <b-form-input v-model="recipient.telephone" />
          <div
            class="input-errors"
            v-for="error of v$.recipient.telephone.$errors"
            :key="error.$uid"
          >
            <small class="text-danger">{{ error.$message }}</small>
          </div>
        </b-form-group>

        <div class="record-recipients">
          <div
            v-for="(r, index) of memoRecipients"
            :key="index"
            class="record-recipients-recipient"
          >
            <b-form-group
              :class="{
                row,
                error:
                  v$.memoRecipients.$each.$response.$errors[index].email.length,
              }"
              :label="`${$t('user.email')} *`"
              class="form-group"
            >
              <b-form-input v-model="r.email" @keydown.space.prevent />
              <div
                class="input-errors"
                v-for="error of v$.memoRecipients.$each.$response.$errors[index]
                  .email"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>

            <b-form-group
              :class="{ row }"
              :label="$t('user.telephone')"
              class="form-group"
            >
              <b-form-input v-model="r.telephone" />
            </b-form-group>

            <b-button variant="secondary" @click="removeMemoRecipient(index)">{{
                $t("record.recipientRemove")
              }}</b-button>
          </div>

          <b-button variant="gray" @click="addMemoRecipient">{{
              $t("record.recipientAdd")
            }}</b-button>
        </div>

        <h4>{{ $t("record.record") }}</h4>
        <b-form-group
          :class="{ row, error: v$.memoUrl.$errors.length }"
          label="Link na záznam *"
          class="form-group"
        >
          <b-form-input v-model="memoUrl" />
          <div
            class="input-errors"
            v-for="error of v$.memoUrl.$errors"
            :key="error.$uid"
          >
            <small class="text-danger">{{ error.$message }}</small>
          </div>
        </b-form-group>

        <b-button class="mt-3" type="submit" variant="primary">
          {{ $t("record.send") }}
        </b-button>

        <b-alert :show="showErr" variant="warning" class="mt-3">
          {{ $t("forms.problem") }}
        </b-alert>

        <b-alert :show="showThanks" variant="success" class="mt-3">
          {{ $t("record.sendMessage") }}
        </b-alert>
      </b-form>
      <b-alert variant="warning" :show="!voucher" class="mb-0 w-100">
        {{ $t("voucher.notFound") }}
      </b-alert>
    </div>
    <div v-else class="record-form-container">
      <b-alert show variant="warning" class="mb-0 w-100">
        {{ $t("voucher.notActivated", { code: this.voucher.code }) }}
      </b-alert>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";
import Search from "@/components/Inputs/Search.vue";

export default {
  components: {
    Search,
  },
  props: {
    voucherObject: { Object, required: true },
    action: { type: String, default: null },
  },
  mixins: [ListUtils, ResourceUtils],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      memoUrl: { required: this.$translateError("required") },
      memoRecipients: {
        $each: helpers.forEach({
          email: {
            required: this.$translateError("required"),
            email: this.$translateError("email"),
          },
        }),
      },
      recipient: {
        givenName: { required: this.$translateError("required") },
        familyName: { required: this.$translateError("required") },
        email: {
          required: this.$translateError("required"),
          email: this.$translateError("email"),
        },
        telephone: { required: this.$translateError("required") },
      },
    };
  },
  data() {
    return {
      voucher: this.voucherObject,
      search: this.voucherObject.code,
      recipient: {
          givenName: '',
          familyName: '',
          email: '',
          telephone: '',
      },
      notActivated: false,
      showThanks: false,
      showErr: false,
      showWarning: false,
    };
  },
  created() {
    this.init()
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  computed: {
    memoUrl: {
      get() {
        if (this.voucher.memoUrl) {
          return this.voucher.memoUrl;
        }
        return "";
      },
      set(val) {
        this.voucher.memoUrl = val;
      },
    },
    memoRecipients: {
      get() {
        if (this.voucher.memoRecipients) {
          return this.voucher.memoRecipients;
        }
        return [];
      },
    },
  },
  methods: {
    searchSubmit(value) {
      this.search = value;
      this.searchVoucher();
    },
    init() {
      this.showWarning = !this.$route.params.id;
      if (
        this.voucher.status === "WAITING" &&
        this.userData.role === "ROLE_PARTNER"
      ) {
        this.notActivated = true;
      }
      this.setRecipient()
    },
    setRecipient() {
      if (this.voucher.passengers && this.voucher.passengers.length) {
        this.recipient.givenName = this.voucher.passengers[0].givenName;
        this.recipient.familyName = this.voucher.passengers[0].familyName;
        this.recipient.email = this.voucher.passengers[0].email;
        this.recipient.telephone = this.voucher.passengers[0].telephone;
      }
    },
    searchVoucher() {
      this.$Vouchers
        .getCollection({ params: { search: this.search } })
        .then((response) => {
          if (
            response.data["hydra:member"] &&
            response.data["hydra:member"].length
          ) {
            this.$router.push(
              `/records/send/${response.data["hydra:member"][0].id}`
            );
            this.voucher = response.data["hydra:member"][0];
            this.init()
            this.showWarning = false
          } else {
            this.voucher = null;
          }
        });
    },
    async onSubmit() {
      this.showThanks = false;
      this.showErr = false;
      const isValid = await this.v$.$validate();
      if (isValid) {
        const passengers = [
          {
            givenName: `${this.recipient.givenName}`,
            familyName: `${this.recipient.familyName}`,
            email: this.recipient.email,
            telephone: this.recipient.telephone,
          },
        ];

        const memoRecipients = []
        this.memoRecipients.forEach((r) => {
          memoRecipients.push({
            email: r.email,
            telephone: r.telephone,
          });
        });

        const body = {
          passengers,
          memoRecipients,
          memoUrl: this.memoUrl,
        };

        if (this.voucher["@id"]) {
            this.$Vouchers
              .updateResourceByUrl({ url: `${this.voucher["@id"]}/send_memo`, body })
              .then((response) => {
                if (response.status === 200) {
                  this.showThanks = true;
                  this.showProblemForm = false;
                }
              })
              .catch(() => {
                this.showErr = true;
              });
        } else {
            this.$Vouchers
                .createResourceByUrl({ url: `/vouchers/send_memo`, body })
                .then((response) => {
                    if (response.status === 200) {
                        this.showThanks = true;
                        this.showProblemForm = false;
                    }
                })
                .catch(() => {
                    this.showErr = true;
                });
        }
        const contacts = []
        passengers.forEach(passenger => { contacts.push({ givenName: passenger.givenName, familyName: passenger.familyName, email: passenger.email, telephone: passenger.telephone, source: 'SENT_MEMO' }) })
        memoRecipients.forEach(recipient => { contacts.push({ givenName: null, familyName: null, email: recipient.email, telephone: recipient.telephone, source: 'SENT_MEMO' }) })
        const contact = { contacts }
        this.$Contacts.createResourceByUrl({ url: '/contacts/multiple', body: contact })
      } else {
        this.$emit("clearAction");
      }
    },
    addMemoRecipient() {
      this.voucher.memoRecipients.push({
        email: "",
        telephone: "",
      });
    },
    removeMemoRecipient(index) {
      this.voucher.memoRecipients.splice(index, 1);
    },
    success() {
      this.$router.push(`/vouchers`);
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
