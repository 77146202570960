<template>
  <div class="partner-custom-form send-record">
    <send-record-form
      v-if="voucher"
      :voucherObject="voucher"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import SendRecordForm from "./components/SendRecordForm.vue";

export default {
  components: {
    SendRecordForm,
  },
  data() {
    return {
      voucher: null,
      action: null,
    };
  },
  created() {
    this.loadVoucher();
    if (!this.$route.params.id) {
        this.voucher = {
            id: null,
            code: null,
            status: 'ACTIVATED',
            validUntil: null,
            activationDate: null,
            memoSendAt: null,
            productVariant: null,
            memoUrl: null,
            partner: null,
            passengers: [{
                givenName: '-',
                familyName: '-'
            }],
            memoRecipients: [],
            order: null
        }
    }
  },
  methods: {
    loadVoucher() {
      const id = this.$route.params.id;
      if (id) {
        this.$Vouchers.getResource({id}, 'voucher_list').then((response) => {
          this.voucher = response.data;
        });
      }
    },
    submitPressed() {
      this.action = "update";
    },
  },
};
</script>
